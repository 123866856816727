import slugify from 'slugify';
import { enableGrapeshot, locale } from '@hubcms/brand';

import {
  CookData,
  GrapeshotData,
  isArticleContext,
  isArticleOrExtraPageContext,
  isAudioVisualArticleContext,
  isB2bTagPage,
  isSectionPage,
  isTagPage,
  isTextualArticleContext,
  Section,
  isElementWithRelation,
  OnAudioVisual,
} from '@hubcms/domain-cook';
import { getPageType } from '@hubcms/utils-tracking';
import { KaChingInitData } from '@hubcms/domain-ads';
import { extractLanguageFromLocale } from '@hubcms/utils-locale';

function sanitizeParameter(parameter: string | null) {
  slugify.extend({
    '<': '',
    '>': '',
  });
  return slugify(parameter ?? '', {
    lower: true,
    strict: true,
  });
}

function getCommaSeparatedSectionString(sectionsList: Array<Pick<Section, 'name' | 'uniqueName' | 'href'>>) {
  return sectionsList.map(section => sanitizeParameter(section.name)).join() || '';
}

export function getKaChingPropsFromCookData(cookData: CookData & GrapeshotData) {
  const { context, sectionParams } = cookData;

  let pageSections = '';
  let articleTaglist = '';

  if (isSectionPage(cookData)) {
    pageSections = sanitizeParameter(cookData.resolution.section.name);
  }

  if (isArticleOrExtraPageContext(context)) {
    pageSections = getCommaSeparatedSectionString(context.allSections);
  }

  const isArticle = isArticleContext(context);

  if (isArticle) {
    articleTaglist = context.tags.map(({ name }) => sanitizeParameter(name)).join(',');
  }
  if (isTagPage(cookData)) {
    articleTaglist = sanitizeParameter(cookData.tagTitle);
  }

  const grapeshotChannels =
    enableGrapeshot && isArticle
      ? /* eslint-disable-next-line camelcase */
        { gs_channels: cookData.grapeshot?.channels.join(',') ?? '' }
      : {};

  const articleDna = isArticle
    ? {
        /* eslint-disable camelcase */
        dna_story_nature: context.fields.storyNature ?? null,
        dna_main_medium: context.fields.mainMedium ?? null,
        dna_genre: context.fields.articleGenre ?? null,
        dna_user_needs: context.fields.userNeeds ?? null,
      }
    : {
        dna_story_nature: null,
        dna_main_medium: null,
        dna_genre: null,
        dna_user_needs: null,
        /* eslint-enable camelcase */
      };

  const initData: KaChingInitData = {
    adunit: isB2bTagPage(cookData) ? sectionParams['b2b.kaching.b2btagpage.adunit'] : sectionParams['b2b.kaching.adunit'],
    blockads: isArticle ? !!context.fields.noAdvertisement : false,
    targeting: {
      /* eslint-disable camelcase */
      article_id: isArticle ? sanitizeParameter(context.id) : '',
      article_taglist: articleTaglist,
      page_sections: pageSections,
      premium: `${isArticle ? !!context.fields.isPremium : false}`,
      page_type: getPageType(cookData),
      page_zipcode: sectionParams['section.communityIdentifier'],
      article_contenttype: isArticle ? sanitizeParameter(context.type) : '',
      article_title: isArticle ? context.title : '',
      lang: extractLanguageFromLocale(locale),
      ...articleDna,
      ...grapeshotChannels,
    },
    video:
      isAudioVisualArticleContext(context) ||
      (isTextualArticleContext(context) &&
        (context.elements ?? []).some(el => el.type === 'audiovisual' && isElementWithRelation<OnAudioVisual>(el))),
    placementGroup: getPageType(cookData),
  };

  return {
    initData,
  };
}
