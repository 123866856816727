import { createFocusCDNUrlForLogo } from '@hubcms/vendor/chameleon/utils-chameleon';

export function createStructuredDataOrganization(origin: string) {
  const urlLogoMain = createFocusCDNUrlForLogo('logo-main', 'svg');
  return {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: origin,
    logo: urlLogoMain,
  };
}
