import Script from 'next/script';
import { ReactElement } from 'react';

import { createStructuredDataOrganization } from '../../utils/createStructuredDataOrganization';

type Props = {
  origin: string;
};

export const StructuredDataOrganization = ({ origin }: Props): ReactElement | null => {
  const structuredDataOrganization = createStructuredDataOrganization(origin);

  return (
    <Script
      type="application/ld+json"
      data-testid="structured-data-organization"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(structuredDataOrganization, null, 2),
      }}
    />
  );
};
