import Script from 'next/script';

import { createStructuredDataWebSite } from '../../utils/createStructuredDataWebSite';

type Props = {
  origin: string;
};
export const StructuredDataWebSiteScript = ({ origin }: Props) => {
  const structuredDataWebSite = createStructuredDataWebSite(origin);

  return (
    <Script
      data-testid="structured-data-web-site"
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(structuredDataWebSite, null, 2),
      }}
    />
  );
};
